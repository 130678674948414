'use client';
import { Footer, Header } from '@sugar/landingpage-components';
import { PropsWithChildren } from 'react';

export default function Template({ children }: PropsWithChildren) {
  return (
    <>
      <Header logoClickable={false} />
        {children}
      <Footer
        withSignup={false}
        copyrightUrl="getasugar"
      />
    </>
  );
}
